import { FlatButton } from 'FlatButton';
import { memo } from 'react';

/**
 * @deprecated Use Modal.tsx instead
 */
const MessageAndButtonModalComponent = ({
    message,
    buttonText,
    onClick,
}: {
    message: string;
    buttonText: string;
    onClick: () => void;
}) => (
    // FIXME: An equivalent component should be added to the Modal module
    <div className="my-5 flex min-h-28 flex-col items-center justify-center">
        <div>{message}</div>
        <FlatButton className="bg-green text-center" onClick={() => onClick()}>
            {buttonText}
        </FlatButton>
    </div>
);

export const MessageAndButtonModal = memo(MessageAndButtonModalComponent) as typeof MessageAndButtonModalComponent;
