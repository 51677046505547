/*

This hook is intended for complex drag-and-drop interfaces with multiple nested drag
areas. Tracks dragging state, depth, and drop zone status for styling and interaction.
Expects refs for the root drag zone (outermost area of concern), the drop target, and any
intermediate areas that should be considered part of the drag zone.

Intermediate Areas:
    Intermediate areas are optional, but useful for more complex drag interfaces with
    multiple nested drag areas or for styling purposes. Drag depth is calculated based
    on the target of drag events, with 0 being outside the drag zone, 1 being the root
    drag zone, 2+ being intermediate areas, and the sum of all refs being the drop zone.

Usage:
    const { isDragging, dragDepth, inDropZone } = useDragState({
        rootRef,           // Ref for outermost drag area
        dropZoneRef,       // Ref for drop target
        intermediateRefs,  // Array of refs for areas between root and drop zone
        disabled,          // Optional flag to disable drag functionality
    });

See the FileDropzone component for an example of this hook in action.
*/

import { useReducer, useCallback, useEffect, useRef } from 'react';

interface DragState {
    isDragging: boolean;
    dragDepth: number;
    inDropZone: boolean;
}

type DragAction =
    | { type: 'SET_DRAGGING'; payload: boolean }
    | { type: 'SET_DRAG_DEPTH'; payload: number }
    | { type: 'SET_IN_DROP_ZONE'; payload: boolean }
    | { type: 'RESET' };

interface UseDragStateProps {
    rootRef: React.RefObject<HTMLElement>;
    dropZoneRef: React.RefObject<HTMLElement>;
    intermediateRefs?: React.RefObject<HTMLElement>[];
    onDrop?: (event: DragEvent) => void;
    disabled?: boolean;
}

const initialState: DragState = {
    isDragging: false,
    dragDepth: 0,
    inDropZone: false,
};

const dragReducer = (state: DragState, action: DragAction): DragState => {
    switch (action.type) {
        case 'SET_DRAGGING':
            return { ...state, isDragging: action.payload };
        case 'SET_DRAG_DEPTH':
            return { ...state, dragDepth: action.payload };
        case 'SET_IN_DROP_ZONE':
            return { ...state, inDropZone: action.payload };
        case 'RESET':
            return initialState;
        default:
            return state;
    }
};

// eslint-disable-next-line max-lines-per-function
export const useDragState = ({
    rootRef,
    dropZoneRef,
    intermediateRefs = [],
    onDrop,
    disabled = false,
}: UseDragStateProps): DragState => {
    const [state, dispatch] = useReducer(dragReducer, initialState);
    const stateRef = useRef(state);
    stateRef.current = state;

    const getDragLevel = useCallback(
        (target: EventTarget | null): number => {
            if (!target || !(target instanceof Node)) return 0;
            if (dropZoneRef.current?.contains(target)) return intermediateRefs.length + 2;
            const intermediateLevel = intermediateRefs.findIndex(ref => ref.current?.contains(target));
            if (intermediateLevel !== -1) return intermediateLevel + 2;
            if (rootRef.current?.contains(target)) return 1;
            return 0;
        },
        [rootRef, dropZoneRef, intermediateRefs],
    );

    const handleDragEnter = useCallback(
        (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();

            if (disabled) return;

            const newDepth = getDragLevel(e.target);
            dispatch({ type: 'SET_DRAGGING', payload: true });
            dispatch({ type: 'SET_DRAG_DEPTH', payload: newDepth });

            if (newDepth === intermediateRefs.length + 2) {
                dispatch({ type: 'SET_IN_DROP_ZONE', payload: true });
            }
        },
        [disabled, getDragLevel, intermediateRefs.length],
    );

    const handleDragOver = useCallback(
        (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();

            if (disabled) return;

            if (dropZoneRef.current?.contains(e.target as Node)) {
                e.dataTransfer!.dropEffect = 'copy';
            }
        },
        [dropZoneRef, disabled],
    );

    const handleDragLeave = useCallback(
        (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();

            if (disabled) return;

            const newDepth = getDragLevel(e.relatedTarget);
            dispatch({ type: 'SET_DRAG_DEPTH', payload: newDepth });

            if (newDepth === 0) {
                dispatch({ type: 'RESET' });
            } else if (newDepth < intermediateRefs.length + 2) {
                dispatch({ type: 'SET_IN_DROP_ZONE', payload: false });
            }
        },
        [disabled, getDragLevel, intermediateRefs.length],
    );

    const handleDrop = useCallback(
        (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();

            if (disabled) return;

            if (stateRef.current.inDropZone) {
                onDrop?.(e);
            }

            dispatch({ type: 'RESET' });
        },
        [disabled, onDrop],
    );

    const handleDragEnd = useCallback(() => {
        dispatch({ type: 'RESET' });
    }, []);

    useEffect(() => {
        const root = rootRef.current;
        if (!root) return undefined;

        root.addEventListener('dragenter', handleDragEnter);
        root.addEventListener('dragover', handleDragOver);
        root.addEventListener('dragleave', handleDragLeave);
        root.addEventListener('drop', handleDrop);
        document.addEventListener('dragend', handleDragEnd);

        return () => {
            root.removeEventListener('dragenter', handleDragEnter);
            root.removeEventListener('dragover', handleDragOver);
            root.removeEventListener('dragleave', handleDragLeave);
            root.removeEventListener('drop', handleDrop);
            document.removeEventListener('dragend', handleDragEnd);
        };
    }, [rootRef, handleDragEnter, handleDragOver, handleDragLeave, handleDrop, handleDragEnd]);

    return state;
};

export default useDragState;
