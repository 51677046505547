import { memo, useCallback } from 'react';
import moment, { type Moment } from 'moment-timezone';
import { TextField } from '@mui/material';
// Note that we are importing from @mui/x-date-pickers multiple times because this lib does not have any default exports on it's components
// this means our babel plugin can't auto split these components into multiple imports, so this is the best option for now.
import {
    DateTimePicker as MuiDatePicker,
    type DateTimePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { type Optional } from '@Types';
import { useFormContext, Controller } from 'FrontRoyalReactHookForm';

type PickerProps = Optional<MuiDatePickerProps<Moment, Moment>, 'onChange' | 'value' | 'renderInput'>;

type DatePickerProps = PickerProps & {
    name: string;
    timezone?: string;
    views?: Array<string>;
};

const dateFormatter = (date: Moment | null, timezone: string) =>
    date?.isValid() ? moment.tz(date, timezone) : moment().tz(timezone);

const DatePickerComponent = ({
    name,
    timezone = moment.tz.guess(),
    views = ['year', 'month', 'day', 'hours', 'minutes'],
    ...rest
}: DatePickerProps) => {
    const { control, getFieldState } = useFormContext<{ [name: string]: Moment }>();
    const formatDate = useCallback((date: Moment | null) => dateFormatter(date, timezone), [timezone]);
    const { error, isDirty } = getFieldState(name);

    return (
        <Controller
            control={control}
            name={name}
            defaultValue={moment()}
            render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MuiDatePicker
                        inputFormat="YYYY/MM/DD h:mm A z"
                        views={views}
                        OpenPickerButtonProps={{ 'aria-label': 'change date', style: { width: '40px' } }}
                        InputProps={{ error: isDirty && !!error }}
                        renderInput={textProps => (
                            <TextField
                                fullWidth
                                {...textProps}
                                inputProps={{ style: { border: 0 }, ...textProps.inputProps }}
                            />
                        )}
                        showToolbar
                        disableMaskedInput
                        label={name}
                        value={formatDate(field.value)}
                        onChange={e => field.onChange(formatDate(e))}
                        {...rest}
                    />
                </LocalizationProvider>
            )}
        />
    );
};

const compareFn = (prev: DatePickerProps, next: DatePickerProps) =>
    prev.label === next.label && prev.timezone === next.timezone;

export const DatePicker = memo(DatePickerComponent, compareFn) as typeof DatePickerComponent;

export default DatePicker;
