import { type FC, useMemo } from 'react';
import {
    createBrowserRouter,
    createHashRouter,
    createRoutesFromElements,
    RouterProvider,
    Route,
    Outlet,
    useNavigate,
    useHref,
} from 'react-router-dom';
import { ErrorBoundary, type FallbackProps } from 'react-error-boundary';
import { getResourcesRoutes } from 'Resources';
import { getTutorBotAiAdvisorRoutes } from 'TutorBotAiAdvisorChat';
import { getStudentDashboardRoutes } from 'StudentDashboard';
import { getNominationsRoutes } from 'Nominations';
import { getChooseInstitutionRoutes } from 'Institutions/ChooseInstitution/Routes';
import {
    cleanUpRouteResolution,
    useEmitRouteChangeSuccessOnReactRouteChange,
    useRespondToAngularRouteChanges,
} from 'FrontRoyalAngular';
import { HeroUIProvider } from '@heroui/react';
import { LoadingBoundary } from 'LoadingBoundary';
import { globalDependenciesLoader } from 'RouteLoaders';
import { angularInjectorProvider } from 'Injector';
import { type FrontRoyalRouteService } from 'NavigationHelpers';

const routeArray = createRoutesFromElements(
    <Route
        path="/"
        element={<Outlet />}
        loader={async () => {
            // If we don't set $route.frontRoyalIsResolvingRoute to true, the angular app won't show a spinner
            // while the global dependencies are loading. See useEmitRouteChangeSuccessOnReactRouteChange to
            // see where $route.frontRoyalIsResolvingRoute gets set to false for react routes.
            const $route = angularInjectorProvider.get<FrontRoyalRouteService>('$route');
            if ($route.current?.$$route?.routeHandledBy !== 'react') return null;

            $route.frontRoyalIsResolvingRoute = true;
            return globalDependenciesLoader();
        }}
    >
        {getResourcesRoutes()}
        {getTutorBotAiAdvisorRoutes()}
        {getStudentDashboardRoutes()}
        {getNominationsRoutes()}
        {getChooseInstitutionRoutes()}
        <Route path="*" element={null} /> {/* Let angular render the route */}
    </Route>,
);

const EmitRouteChangeSuccess: FC = () => {
    useEmitRouteChangeSuccessOnReactRouteChange();
    return null;
};

// This serves as a catch-all for errors that occur during route resolution. It simply does some
// mandatory cleanup and then rethrows the error so that the error boundary in the LoadingBoundary
// can display an appropriate message.
//
// NOTE: This logic being intended for errors that occur during route resolution, this logic does
// get triggered for errors that occur outside of route resolution, however, it shouldn't cause
// any issues if we trigger the route resolution cleanup logic here.
const HandleErrorOnRouteResolution: FC<FallbackProps> = ({ error }) => {
    cleanUpRouteResolution(angularInjectorProvider.requireInjector());
    throw error;
};

function RoutingRoot() {
    const navigate = useNavigate();

    // See https://heroui.com/docs/guide/routing#react-router for an explanation of the navigate and useHref props
    return (
        <LoadingBoundary>
            <HeroUIProvider navigate={navigate} useHref={useHref} className="h-full">
                <EmitRouteChangeSuccess />
                <ErrorBoundary FallbackComponent={HandleErrorOnRouteResolution}>
                    <Outlet />
                </ErrorBoundary>
            </HeroUIProvider>
        </LoadingBoundary>
    );
}

export function FrontRoyalRouter() {
    const router = useMemo(() => {
        const routerFn = window.CORDOVA ? createHashRouter : createBrowserRouter;
        return routerFn([
            {
                path: '/',
                id: 'root',
                element: <RoutingRoot />,
                children: routeArray, // Assuming `routeArray` contains your child routes
            },
        ]);
    }, []);

    useRespondToAngularRouteChanges(router);

    return (
        <div className="h-full">
            <RouterProvider router={router} />
        </div>
    );
}

export default FrontRoyalRouter;
