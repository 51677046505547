import { memo } from 'react';

/**
 * @deprecated Use Modal.tsx instead, which has its own backdrop
 */
function ModalBackdropComponent({ children }: { children: React.ReactNode }) {
    return (
        <div className="fixed inset-0 z-[10000] bg-black bg-opacity-50 px-2 pt-[55px] backdrop-blur-sm sm:pt-[75px]">
            {children}
        </div>
    );
}

export const ModalBackdrop = memo(ModalBackdropComponent) as typeof ModalBackdropComponent;
