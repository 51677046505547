import { createRoot } from 'react-dom/client';
import { logTinyEvent } from 'TinyEventLogger';
import { FrontRoyalRoot } from './FrontRoyalRoot';

function createRootInMainAppNode() {
    // This element is put on the screen in index.html.erb on web,
    // and hybrid/<application>/www/index.html on mobile.
    const mainAppNode = document.getElementById('app-root');
    if (!mainAppNode) {
        logTinyEvent('app_root_not_found', {});
        throw new Error('The app-root element was not found');
    }

    const root = createRoot(mainAppNode);
    root.render(<FrontRoyalRoot />);
}

export function bootstrapFrontRoyalApp() {
    // On web, we wait for DOMContentLoaded to bootstrap the angular app because that will fire
    // once we've loaded and executed all scripts with the `defer` attribute in the HTML.
    if (!window.CORDOVA) {
        document.addEventListener('DOMContentLoaded', createRootInMainAppNode);
    } else {
        // On mobile, DOMContentLoaded fires before all of our scripts have loaded because we dynamically
        // construct and add the script tags to the HTML at runtime. Meaning the HTML is considered fully
        // parsed before those injected scripts have loaded and executed.
        createRootInMainAppNode();
    }
}
