/* eslint-disable max-classes-per-file */
import { configureStore } from '@reduxjs/toolkit';
import { frontRoyalStoreApi } from 'FrontRoyalStore';
import { cohortsApi } from 'Cohorts';
import { chatSlice } from 'TutorBotChat';
import { themeSlice } from 'Theme';
import { storeProvider, combineApisAndSlices, apiProvider } from 'ReduxHelpers';
import { autoSuggestOptionsApi } from 'AutoSuggestOptions';
import { careerProfilesApi } from 'CareerProfiles';
import { lessonsApi, lessonsEditorApi } from 'Lessons';
import { playlistExamEvaluationsApi } from 'PlaylistExamEvaluation';
import { resourcesApi } from 'Resources/resourcesApi';
import { editAoiRecordsApi } from 'UserAdministrationTab';
import { playlistEditorApi } from 'Playlists';
import { messagingSlice } from 'Messaging';
import { studentNetworkApi } from 'StudentNetwork/StudentNetworkApi';
import { liveKitApi, voiceAgentSlice } from 'TutorBotVoiceAgent';
import { customMiddleware } from './middleware';

const apis = [
    autoSuggestOptionsApi,
    careerProfilesApi,
    cohortsApi,
    editAoiRecordsApi,
    frontRoyalStoreApi,
    lessonsApi,
    lessonsEditorApi,
    playlistEditorApi,
    playlistExamEvaluationsApi,
    resourcesApi,
    liveKitApi,
    studentNetworkApi,
];

const slices = [chatSlice, themeSlice, messagingSlice, voiceAgentSlice];
export function initializeAppStore() {
    if (storeProvider.store) {
        throw new Error('store is already defined');
    }

    apiProvider.add(apis);
    const { reducer, middleware } = combineApisAndSlices({
        apis,
        slices,
        middleware: customMiddleware,
    });

    const store = configureStore({
        reducer,
        middleware,
    });

    storeProvider.store = store;

    return store;
}

// In lesson preview mode, the storeProvider is attached to the iframe.contentWindow
export const getState = () => (window.storeProvider ?? storeProvider).store!.getState();
