import { useState, useEffect, useCallback, type ChangeEvent } from 'react';
import { MenuItem, TextField, FormControl, type SelectChangeEvent } from '@mui/material';
import { parsePhoneNumber, type CountryCode, type ParseError } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'FrontRoyalReactHookForm';
import useLocalizedCountries from 'Countries/countriesUtils';
import { formattedTelephoneNumber, formattedTelephonePlaceholder } from 'Telephone';
import './TelephoneInput.styles.scss';
import 'Telephone/locales/telephone/tel_input-en.json';

type TelephoneInputProps = {
    name: string;
    telephoneNumber?: string | null;
    defaultCountryCode?: CountryCode | null;
    includeExtension?: boolean;
    label?: string;
    disabled?: boolean;
};

type Country = {
    formattedLabel: string;
    countryCode: string;
    value: string;
    text: string;
    telPrefix: string;
};

const getTelPrefix = (code: CountryCode, countries: Country[]) =>
    countries.find(country => country.countryCode === code)?.telPrefix;

// eslint-disable-next-line max-lines-per-function
export const TelephoneInput = ({
    telephoneNumber,
    label,
    defaultCountryCode: providedDefaultCountryCode = 'US',
    includeExtension = false,
    disabled,
    name,
}: TelephoneInputProps) => {
    const { control, setFieldValue, watch } = useFormContext();
    const value = watch(name);

    // fall back to US if null is passed in
    const defaultCountryCode = providedDefaultCountryCode || 'US';

    const { t } = useTranslation('back_royal');
    const countries = useLocalizedCountries()
        .filter(option => option.text.length > 0)
        .map(option => ({
            ...option,
            formattedLabel: `${option.text} (${option.telPrefix})`,
            countryCode: option.value,
        }));

    const [countryCode, setCountryCode] = useState(defaultCountryCode || 'US');
    const [phoneNumber, setPhoneNumber] = useState(telephoneNumber || '');
    const [isValidNumber, setIsValidNumber] = useState(true);
    const [phoneExtension, setPhoneExtension] = useState('');

    const validatePhoneNumber = useCallback(
        (number: string, code: CountryCode) => {
            let parsedPhoneNumber;

            try {
                parsedPhoneNumber = parsePhoneNumber(number, code);

                if (parsedPhoneNumber.isValid()) {
                    const formattedNumber = formattedTelephoneNumber(parsedPhoneNumber.number, code)!;

                    setPhoneNumber(formattedNumber);
                    setIsValidNumber(true);
                    setFieldValue(name, `${parsedPhoneNumber.number}${phoneExtension}`);
                } else {
                    setIsValidNumber(false);
                }
            } catch (error) {
                const parseError = error as ParseError;
                if (parseError.message === 'NOT_A_NUMBER') {
                    setFieldValue(name, null);
                    setIsValidNumber(true);
                    return;
                }
                setIsValidNumber(false);
            }
        },
        [setFieldValue, phoneExtension, name],
    );

    const handleCountryChange = (event: SelectChangeEvent<unknown>) => {
        const selectedCode = event.target.value as CountryCode;
        setCountryCode(selectedCode);
        validatePhoneNumber(phoneNumber, selectedCode);
    };

    const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(event.target.value);
        validatePhoneNumber(event.target.value, countryCode);
    };

    const handleExtensionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPhoneExtension(event.target.value);
    };

    useEffect(() => {
        if (telephoneNumber && !value && value !== null) {
            let number;
            let code;

            try {
                const phone = parsePhoneNumber(telephoneNumber);
                number = phone.number;
                code = phone.country!;
            } catch {
                validatePhoneNumber(telephoneNumber, defaultCountryCode);
                return;
            }

            if (number && code) {
                setCountryCode(code);
                validatePhoneNumber(number, code);
            }
        }
    }, [telephoneNumber, defaultCountryCode, validatePhoneNumber, value]);

    return (
        <Controller
            control={control}
            name={name}
            render={() => (
                <FormControl className="telephone-wrapper front-royal-form-container">
                    <div className="inner-input-wrapper">
                        <TextField
                            select
                            variant="outlined"
                            label={label}
                            className="country-wrapper"
                            inputProps={{
                                name: 'country-code',
                                'aria-label': 'country-select',
                            }}
                            SelectProps={{
                                renderValue: val => getTelPrefix(val as CountryCode, countries),
                                value: countryCode,
                                disabled,
                                onChange: handleCountryChange,
                                className: 'country-code-select',
                            }}
                        >
                            {countries.map(option => (
                                <MenuItem value={option.countryCode} key={option.countryCode}>
                                    {option.formattedLabel}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            variant="outlined"
                            inputProps={{
                                type: 'tel',
                                'aria-label': 'telephone-input',
                                className: 'phone-input',
                            }}
                            onKeyPress={event => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            disabled={disabled}
                            placeholder={formattedTelephonePlaceholder(countryCode)}
                            value={phoneNumber}
                            onChange={handlePhoneChange}
                            error={!isValidNumber}
                            className={`phone-wrapper ${includeExtension ? 'include-extension' : ''}`}
                        />

                        {includeExtension && (
                            <TextField
                                variant="outlined"
                                inputProps={{
                                    type: 'tel',
                                    'aria-label': 'telephone-extension',
                                }}
                                onKeyPress={event => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                placeholder={t('telephone.tel_input.extension_placeholder') || undefined}
                                value={phoneExtension}
                                disabled={disabled}
                                onChange={handleExtensionChange}
                                className="extension-wrapper"
                            />
                        )}
                    </div>

                    {!isValidNumber && (
                        <span className="error-label" data-testid="error-label">
                            {t('telephone.tel_input.invalid_phone')}
                        </span>
                    )}
                </FormControl>
            )}
        />
    );
};

export default TelephoneInput;
